<template>
  <section class="auth-wrapper">
    <v-card max-width="500">
      <v-card-title class="d-flex justify-center">
        Увійти
      </v-card-title>
      <alert v-if="alert" :alert-data="alert"/>
      <v-card-text>
        <v-form
          ref="loginForm"
          @submit.prevent="submitLogIn"
          v-model="valid"
          lazy-validation
        >
          <v-row>
            <v-col class="pb-0" cols="12">
              <v-text-field
                dense
                outlined
                ref="email"
                v-model="user.email"
                :rules="rules.email"
                label="E-mail"
                required
              >
              </v-text-field>
            </v-col>
            <v-col class="pb-0 pt-0" cols="12">
              <v-text-field
                dense
                outlined
                v-model="user.password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show ? 'text' : 'password'"
                label="Пароль"
                counter
                @click:append="show = !show"
              >
              </v-text-field>
            </v-col>
            <v-col class="d-flex justify-center pa-0 mb-3" cols="12">
              <v-btn :disabled="!valid" color="success" type="submit">Увiйти</v-btn>
            </v-col>
            <v-col class="d-flex justify-center" cols="12">
              <router-link :to="{ name: 'ResetPassword', query: $route.query }"
              >відновити пароль
              </router-link
              >
              <span class="ml-2 mr-2">або</span>
              <router-link :to="{ name: 'Register', query: $route.query }"
              >зареєструватися
              </router-link
              >
            </v-col>
          </v-row>
        </v-form>
<!--        <social-login :auth="true"/>-->
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Alert from '@/shared/components/Alert'
// import SocialLogin from '../components/SocialLogin'

export default {
  name: 'Login',
  components: { Alert },
  data: () => ({
    valid: true,
    show: false,
    user: {},
    rules: {
      required: (value) => !!value || "Обов'язковe поле",
      min: (v) => (v && v.length >= 8) || 'Мінимум 8 символів',
      email: [
        (v) => !!v || "Обов'язковe поле",
        (v) => /.+@.+\..+/.test(v) || 'E-mail має бути дійсним'
      ]
    },
    alert: {}
  }),
  computed: {
    ...mapGetters('auth', ['isAuthed'])
  },
  watch: {
    '$route.query': {
      handler (query) {
        const { status, msg } = query
        if (status && msg) {
          this.setAlert(query)
          this.$router.replace({
            query: {}
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    if (this.isAuthed || this.$cookies.isKey('_token')) {
      this.redirectTo('/')
    }
  },
  methods: {
    ...mapActions('auth', ['logIn']),
    ...mapActions('user', ['fetchUser']),
    submitLogIn () {
      this.alert = {}
      if (this.$refs.loginForm.validate()) {
        this.logIn(this.user)
          .then(() => {
            this.redirectTo('/')
          })
          .catch((e) => {
            switch (e.response.status) {
              case 401:
                this.setAlert({
                  status: 'error',
                  msg: 'Не вірні логін або пароль'
                })
                break
            }
          })
      }
    },
    redirectTo (path) {
      const redirect = this.$route.query.redirect || path
      const query = Object.fromEntries(
        Object.entries(this.$route.query).filter((el) => el[0] !== 'redirect')
      )
      this.$router.push({ path: redirect, query }).catch(() => {
      })
    },
    setAlert ({ status, msg, timeout = 5000 }) {
      this.alert = {
        status,
        msg
      }
      setTimeout(() => (this.alert = {}), timeout)
    }
  }
}
</script>
